.card-board {
    border: 1px solid $black;
    width: 400px;
    min-height: 600px;
    max-height: 700px;
    padding: 10px;
    position: relative;
    @media #{$mobile} {
        max-height: fit-content;
        width: 330px;
    }
    &__header {
        @include flex();
        justify-content: flex-start;
        margin-top: 50px;
    }
    &__Logo {
        height: 70px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -15px;
        background: white;
    }
    &__headerImage {
        height: 100px;
        @media #{$mobile} {
            height: 85px;
        }
    }
    &__headerTitle {
        text-transform: uppercase;
    }
    &__infos {
        margin-left: 10px;
        color: #107f4a;
        & > h4 {
            margin: 0;
            color: #201e5f;
        }
        & > h5 {
            display: block;
            font-size: 16px;
            @media #{$mobile} {
                font-size: 11px;
            }
        }
    }
    &__InfoTitle {
        margin: 15px 0 0;
    }
    &__headerSubTitle {
        margin: 0;
    }
    &__expertise {
        font-size: 14px;
        text-align: justify;
        line-height: 20px;
        @media #{$mobile} {
            font-size: 12px;
        }
    }
}