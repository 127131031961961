@import "sass/style.scss";
@import "./Components/Header/Header.scss";
@import "./Components/ContactUs/ContactUs.scss";
@import "./Components/Hero/Hero.scss";
@import "./sass/ContactUsPage.scss";
@import "./Components/Loader/Loader.module.scss";
@import "./Components/TeamCard/TeamCard.scss";
@import "./Components/AboutUs/AboutUs.scss";
@import "./Components/Footer/Footer.scss";
@import "./Components/SectionContainer/SectionContainer.scss";
@import "./sass/Partners.scss";
@import "./Components/PartnerCard/partnerCard.scss";
@import "./Components/News/News.scss";
@import "./sass/NewsPageItem.scss";
@import "./Components/CapabilitiesItem/CapabilitiesItem.scss";
@import "./Components/CardBoard/CardBoard.scss";
body {
  padding: 0;
  margin: 0;
  font-family: $secondaryFont;
  &.team-open {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.56);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
    }
  }
}
.container {
  width: 95%;
  margin: auto;
}

.heroSection {
  position: relative;
}

.heroSection__caption {
position: absolute;
bottom: 0;
left: 50%;
transform: translate(-50%);
height: 100px;
@media #{$mobile} {
  height: 60px;
}
}

.capabilitiesLabel,.newsLabel {
  bottom: 40%;
  right: 5%;
  left: auto;
  transform: none;
  height: 150px;
  @media #{$mobile} {
    height: 60px;
    right: auto;
    left: 50%;
    transform: translate(-50%);
  }
}

.newsLabel {
  height: 90px;
  @media #{$mobile} {
    height: 50px;
  }
}

.header-video {
  box-sizing: border-box;
  clip-path: polygon(30% 0, 100% 7%, 100% 100%, 100% 92%, 70% 100%, 0 93%, 0 8%);

  & video {
    max-width: 100%;
    width: 100%;
  }
}

.homepageHeader {
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  color:#201e5f;
  &::after {
    content: " News";
    color: #0f7f40;
  }
  @media #{$mobile} {
    font-size: 30px;
  }
}
.newsBlocks {
  @include flex(row, wrap, center);
  gap: 15px;
}

.slick-dots {
  bottom: -30px !important;
}
.slick-dots li button:before {
  font-size: 14px !important;
}

.slick-arrow {
  &.slick-next {
    right: 100px;
    @media #{$mobile} {
      right: 10px !important;
    }
    &::before {
      color: #201e5f;
      font-size: 30px;
      @media #{$mobile} {
        font-size: 20px;
      }
    }
  }
  &.slick-prev {
    @media #{$mobile} {
      left: 10px;
    }
    left: 100px;
    z-index: 1;
    &::before {
      color: #201e5f;
      font-size: 30px;
      @media #{$mobile} {
        font-size: 20px;
      }
    }
  }
}

.capabilities {
  @include flex(row, wrap, space-between);
  width: 70%;
  margin: auto;
}
