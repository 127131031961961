@import "../../sass/style.scss";

.heroContainer {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 450px;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  @media #{$mobile} {
    height: 250px;
    background-position: left;
  }
  &__textSection {
    @include center_middle_align();
  }
  &__title {
    font-size: 60px;
    font-weight: 700;
    margin: 0;
    @media #{$tablet} {
      font-size: 50px;
    }
    @media #{$mobile} {
      font-size: 40px;
    }
  }
  &__subTitle {
    font-size: 30px;
    font-weight: 700;
  }
}
