@import "../../sass/style.scss";

.aboutUs {
  &__title {
    font-size: 40px;
    font-family: $secondaryFont;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color:#201e5f;
    @media #{$mobile} {
      font-size: 30px;
    }
  }
  &__title::after {
    content: ' GEN';
    color: #0f7f40 ;
  }
  &__infowrapper {
    position: relative;
    margin: auto;
  }
  &__hidden {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease;
  }
  .expandContainer {
    max-height: 1200px;
    transition: max-height 0.7s ease;
  }
  &__morebutton {
    color: #0f7f40;
    font-family: $secondaryFont;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    background-color: none;
    border: none;
    @include center_align();
    bottom: 0;
    background-color: transparent;
    border: none;
    margin: auto;
    cursor: pointer;
    &::after {
      content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #0f7f40;
    transform: scaleX(0);
    transition: all 0.3s;
  }
  &:hover {
    &::after {
      transform: scaleX(1);
    }  
  }
  }
  &__paragraphe {
    text-align: center;
    font-family: $secondaryFont;
    line-height: 25px;
    text-align: center;
    margin: auto;
    width: 90%;
    line-height: 30px;
    @media #{$mobTab} {
      font-size: 14px;
    }
  }
}

.values-container {
@include flex(row,wrap);
align-items: flex-start;
margin-top: 20px;
@media #{$mobile} {
  display: block;
  margin: auto;
}
}

.wrapper {
  padding: 20px;
  background-color: white;
  width: 33.3%;
 margin-left: 20px;
 min-height: 460px;
 &:first-child {
  margin-left: 0;
 }
 @media #{$mobile} {
  margin: 20px auto 0 auto;
  width: 90%;
  min-height: fit-content;
 }
&__image {
    height: 100px;
    display: block;
    margin: 20px auto 20px auto;
  }
  &__title {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    color: $black;
    margin-bottom: 20px;
    font-family: $secondaryFont;
    color:#201e5f;

  }
  .mission::after {
    content: ' Mission';
    color: #0f7f40;
  }
  .vission::after {
    content: ' Vision';
    color: #0f7f40;
  }
  &__paragraphe {
    width: 90%;
    font-family: $secondaryFont;
    text-align: center;
    margin: auto;
    line-height: 30px;
    @media #{$mobile} {
      font-size: 14px;
    }
  }
}