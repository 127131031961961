@import "../../sass/style.scss";
// .header-container {
//   width: 100%;
//   height: 90px;
//   display: flex;
//   position: sticky;
//   top: 0;
//   z-index: 100;
//   background-color: $white;

//   &__wrapper {
//     @include flex($justify-content: space-between);
//   }

//   &__navbar {
//     @media #{$mobile} {
//     }
//     height: 0;
//     background-color: $white;
//     position: absolute;
//     width: 100%;
//     top: 90px;
//     transition: 0.6s;
//     left: 0;
//     right: 0;
//     overflow-y: scroll;

//     &::-webkit-scrollbar {
//       width: 0;
//     }

//     &::-webkit-scrollbar-thumb {
//       display: none;
//     }

//     &::-webkit-scrollbar-button {
//       display: none;
//     }
//   }
//   .openMobileMenu {
//     height: 100vh;
//   }
//   &__logo {
//     height: 90px;
//     display: block;
//   }

//   &__link {
//     box-sizing: border-box;
//     text-decoration: none;
//     color: $black;
//     text-decoration: none;
//     text-transform: uppercase;
//     padding: 5px;
//     display: block;
//     font-weight: 500;
//     &:hover,
//     &:active {
//       color: #0f7f40;
//     }
//     &:first-child {
//       margin: 0;
//     }
//     margin: auto;
//   }
//   .active-link {
//     color: #0f7f40;
//   }

//   &__list {
//     padding: 20px 0;
//     margin: 0;
//     list-style: none;
//     display: block;
//     position: relative;
//     margin: auto;
//     text-align: center;
//     top: 10%;
//     @media #{$mobile} {
//       height: 600px;
//     }
//   }

//   &__item {
//     font-family: $mainFont;
//     color: $black;
//     display: block;
//     cursor: pointer;

//     margin: 30px auto;
//     font-size: 26px;
//   }
//   .sublinkArrow {
//     position: relative;
//     &::after {
//       content: "";
//       position: absolute;
//       top: 10px;
//       width: 10px;
//       height: 10px;
//       margin-left: 12px;
//       margin-bottom: 10px;
//       border: 2px solid $black;
//       border-bottom: none;
//       border-left: none;
//       transform: rotate(135deg);
//       transition: all ease-out 0.2s;
//     }
//   }
//   &__sublist {
//     display: none;
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     background-color: $white;
//     position: relative;
//     width: fit-content;
//   }
//   &__subitem {
//     cursor: pointer;
//     display: block;
//     width: 100%;
//     &:hover {
//       color: #0f7f40;
//     }
//     line-height: 15px;
//     font-size: 26px;
//     margin: 30px auto;
//   }
//   .opensublist {
//     display: block;
//     margin: auto;
//     width: 100%;
//   }
//   .closesublist {
//     display: none;
//   }
//   .arrowopen {
//     &::after {
//       top: 15px;
//       transition: all ease-out 0.2s;
//       transform: rotate(315deg);
//     }
//   }

//   .burgermenu_container {
//     position: relative;
//     flex-direction: column;
//     padding: 10px;
//     cursor: pointer;
//     display: flex;
//     i {
//       background-color: $black;
//       width: 30px;
//       height: 2px;
//       margin: 4px auto;
//       border-radius: 2px;
//       transition: all ease 0.5s;
//     }
//   }

//   .openbtn:nth-child(1) {
//     transform: rotate(48deg) translateY(15px);
//   }

//   .openbtn:nth-child(2) {
//     opacity: 0;
//   }

//   .openbtn:nth-child(3) {
//     transform: rotate(-51deg) translateY(-15px);
//   }

//   .closebtn:nth-child(1) {
//     transform: rotate(0) translateY(0);
//   }

//   .closebtn:nth-child(2) {
//     opacity: 1;
//   }

//   .closebtn:nth-child(3) {
//     transform: rotate(0) translateY(0);
//   }
// }

.header {
  height: 90px;
  background-color: white;
  top: 0;
  z-index: 100;
  position: sticky;
  &__content {
    @include flex(row, nowrap, space-between);
  }
  &__logo {
    height: 80px;
  }
  &__navbar {
    margin-right:70px ;
    @media #{$mobile} {
      height: 0;
      background-color: $white;
      position: absolute;
      width: 100%;
      top: 90px;
      transition: 0.6s;
      left: 0;
      right: 0;
      overflow-y: scroll;
      z-index: 100;
      margin-right: 0;
    }
    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
  .openMobileMenu {
    height: 100vh;
  }
  &__list {
    padding: 0;
    margin: 0;
    @media #{$mobile} {
      padding: 20px 0;
      display: block;
      position: absolute;
      width: 100%;
      margin: auto;
      text-align: center;
      background-color: white;
      z-index: 100;
      height: 600px;
    }
  }
  &__item {
    display: inline-block;
    margin-left: 15px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    color: #201e5f;
    cursor: pointer;
    &:nth-child(1) {
      @media #{$mobile} {
        margin: auto;
      }
    }
    &:hover {
      color: #0f7f40;
    }
    @media #{$mobile} {
      display: block;
      margin: 20px 0;
      font-size: 25px;
    }
  }
  &__link {
    text-decoration: none;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    color: #201e5f;
    display: block;
    width: 100%;
    &:hover {
      color: #0f7f40;
    }
    @media #{$mobile} {
      font-size: 25px;
    }
    @media #{$tablet} {
      font-size: 14px;
    }
  }
  .openSubmenu {
    display: block;
  }
  .submenu {
    position: relative;
    &:hover {
      .header__sub-List {
        @media (min-width: 769px) {
          display: block;
        }
      }
      &:after {
        @media (min-width: 769px) {
          top: 10px;
          transition: all ease-out 0.2s;
          transform: rotate(315deg);
        }
      }
    }
    &::after {
      @media (min-width: 769px) {
        content: "";
        position: absolute;
        top: 5px;
        left: calc(100% + 10px);
        width: 8px;
        height: 8px;
        border: 2px solid $black;
        border-bottom: none;
        border-left: none;
        transform: rotate(135deg);
        transition: all ease-out 0.2s;
        @media #{$mobile} {
          left: calc(50% + 90px);
        }
      }
    }
  }
  .openArrow::after {
    @media #{$mobile} {
      content: "";
      position: absolute;
      top: 5px;
      left: calc(100% + 10px);
      width: 8px;
      height: 8px;
      border: 2px solid $black;
      border-bottom: none;
      border-left: none;
      transform: rotate(135deg);
      transition: all ease-out 0.2s;
      @media #{$mobile} {
        left: calc(50% + 90px);
      }
    }
  }
  .closeArrow::after {
    @media #{$mobile} {
      content: "";
      position: absolute;
      top: 10px;
      width: 8px;
      height: 8px;
      border: 2px solid $black;
      border-bottom: none;
      border-left: none;
      transition: all ease-out 0.2s;
      transform: rotate(315deg);
      @media #{$mobile} {
        left: calc(50% + 90px);
      }
    }
  }
  .open__Sub-List {
    @media #{$mobile} {
      display: block;
    }
  }
  &__sub-List {
    display: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    z-index: 10;
    padding: 10px;
    @media #{$mobile} {
      position: relative;
    }
  }
  &__subItem {
    display: block;
    white-space: nowrap;
    margin-top: 15px;
  }
  &__burgerMenu-Container {
    display: none;
    position: relative;
    flex-direction: column;
    padding: 10px;
    cursor: pointer;

    i {
      background-color: $black;
      width: 30px;
      height: 2px;
      margin: 4px auto;
      border-radius: 2px;
      transition: all ease 0.5s;
    }
    @media #{$mobile} {
      display: flex;
    }
  }

  .openbtn:nth-child(1) {
    transform: rotate(48deg) translateY(15px);
  }

  .openbtn:nth-child(2) {
    opacity: 0;
  }

  .openbtn:nth-child(3) {
    transform: rotate(-51deg) translateY(-15px);
  }

  .closebtn:nth-child(1) {
    transform: rotate(0) translateY(0);
  }

  .closebtn:nth-child(2) {
    opacity: 1;
  }

  .closebtn:nth-child(3) {
    transform: rotate(0) translateY(0);
  }
  &__homeLink {
    position: absolute;
    right: 50px;
    @media #{$mobile} {
      right: 80px;
    }
  }
  &__homeIcon {
    width:30px;
  }
}
