@import "./style.scss";

.contactUs {
  margin: 20px 0;
  padding: 40px 0;
  @include flex(row, nowrap, center);
  @media #{$mobTab} {
    display: block;
  }
}
