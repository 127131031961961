@import "../../sass/style.scss";
.news-container {
  width: 400px;
  min-height: 370px;
  text-align: justify;
  border: 2px solid #201e5f;
  padding: 10px;
  position: relative;
  &__image-container {
    @include flex(row, nowrap, space-around);
    margin-top: 70px;
  }
  &__image {
    width: 130px;
  }
  &__paragraphe {
    font-size: 14px;
    line-height: 20px;
    @media #{$mobile} {
      font-size: 12px;
    }
  }
  @media #{$mobile} {
    width: 70%;
    margin: 10px auto;
  
  }
  &__website-logo {
    height: 70px;
    position: absolute;
    left: 50%;
    top: -13px;
    transform: translateX(-50%);
    background-color: white;
    display: block;
  }
}
