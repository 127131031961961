@import './style.scss';
.newsPageItem {
    margin: auto;
    &__image-container {
        @include flex(row, nowrap, center);
        @media #{$mobile} {
            display: block;
            margin: auto;
            width: fit-content;
        }
    }
    &__image {
        width: 350px;
        @media #{$mobile} {
            width: 300px;
            display: block;
        }
    }
    &__paragraphe {
        text-align: justify;
        line-height: 30px;
    }
}