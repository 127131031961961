@import '../../sass/style.scss';

.partnerCard{
    background-color: $white;
    width: 80%;
    margin: 100px auto 0;
    min-height: 400px;
    &__image {
        width: 300px;
        aspect-ratio: 3/1;
        object-fit: contain;
        display: block;
        margin: auto;
    }
    &__paragraphe {
        text-align: center;
        font-size: 16px;
        color: $black;
        line-height: 30px;
    }
}