@import "../../sass/style.scss";

.loader_container {
    position: absolute;
    left: 120px;
    bottom: 25px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #201e5f;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
