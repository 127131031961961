@import "../../sass/style.scss";

.footer {
  margin-top: 50px;
  background-color: #201e5f;
  padding: 20px 0;
  &__logo {
    height: 90px;
    display: block;
    margin: auto;
  }
  &__list {
    padding: 0;
    list-style: none;
    width: fit-content;
    margin: 10px auto;
    text-align: center;
  }
  &__item {
    display: block;
    margin-top: 10px;
  }
  &__linkItem {
    text-decoration: none;
    color: $white;
    font-weight: 500;
    &:hover {
      color: #0f7f40;
    }
  }
  &__copyright {
    color: $white;
    text-align: center;
    font-size: 14px;
    display: block;
    margin: auto;
  }
}