@import "../../sass/style.scss";

.teamCard {
  width: 423px;
  min-height: 640px;
  padding: 10px;
  box-sizing: border-box;
  background: $white;
  transition: all 0.5s ease;
  border: 1px solid black;
  @media #{$mobile} {
    width: 330px;
    margin: 0;
    height: fit-content;
  }
  &__iconInfo {
    height: 30px;
  }
  &__textInfo {
    width: 80%;
    font-size: 14px;
  }
  &__linkedinLogo {
    height: 20px;
    display: block;
    margin: auto;
  }
&__info {
  margin-top: 10px;
  min-height: 65px;
  @include flex(row,nowrap,space-around);
}
  &__Wrapper {
    position: relative;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    min-height: 150px;
  }
 &__Name {
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
}
&__role {
    text-transform: uppercase;
    display: block;
    color: $lightGrey;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}
}