@import "../../sass/style.scss";
.sectionContainer {
  background-color: #eeeeee;
  margin-top: -30px;
  padding: 0 0 30px 0;
  &__title {
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    color: $white;
    margin: 0;
    font-family: $secondaryFont;
    color: $black;
    text-transform: uppercase;
    color:#201e5f;
    &::after {
      content: " Team";
      color: #0f7f40;
    }
    @media #{$mobile} {
      font-size: 30px;
    }
  }
  &__teamInfo {
    position: relative;
    margin: 10px auto;
  }
  &__hidden {
    max-height: 0;
    transition: max-height 0.7s ease;
    overflow: hidden;
  }
  .hidecontainer {
    max-height: 0;
    transition: max-height 0.7s ease;
    overflow: hidden;
  }
  .expandContainer {
      max-height: 1200px;
    transition: max-height 0.7s ease;
  }
  &__morebutton {
    color: #0f7f40;
    font-family: $secondaryFont;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    background-color: none;
    border: none;
    @include center_align();
    bottom: 0;
    background-color: transparent;
    border: none;
    margin: auto;
    cursor: pointer;
    &::after {
        content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #0f7f40;
      transform: scaleX(0);
      transition: all 0.3s;
    }
    &:hover {
      &::after {
        transform: scaleX(1);
      }  
    }
  }
  &__paragraphe {
    text-align: center;
    font-family: $secondaryFont;
    line-height: 25px;
    text-align: center;
    margin: auto;
    width: 90%;
    @media #{$mobTab} {
      font-size: 14px;
    }
  }

  &__team {
    height: fit-content;
    padding: 10px 0;
    width: 100%;
    @include flex(row, wrap, center);
    gap: 5px;
  }
  &__partners {
    width: 90%;
    margin:auto;
    background-color: $white;
    padding: 10px;
    border-radius: 20px;
    margin-top: 20px;
  }
  &__titlepartners {
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    color: $white;
    font-family: $secondaryFont;
    color: $black;
    margin-bottom: 10px;
    text-transform: uppercase;
    color:#201e5f;
    &::after {
      content: " Partners";
      color: #0f7f40;
    }
    @media #{$mobile} {
      font-size: 30px;
    }
  }
  &__subButton {
    display: block;
    width: fit-content;
    font-weight: 400;
    font-size: 20px;
    color: $white;
    margin: 10px auto 20px auto;
    padding: 10px;
    margin-top: 0;
    font-family: $secondaryFont;
    color: $black;
    background-color: #201e5f;
    color: $white;
    border-radius: 10px;
    text-decoration: none;
    border: 2px solid #201e5f;
    &:hover,
    &:active {
      background-color: transparent;
      border: 2px solid #201e5f;
      color: #201e5f;
    }
  }
  &__wrapper {
    @include flex(row, wrap, center);
  }
  &__image {
    width: 250px;
    margin-left: 20px;
    aspect-ratio: 3/1;
    object-fit: contain;
    transition: all ease 0.5s;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition: all ease 0.5s;
    }
  }
}
